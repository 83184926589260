




















































import Contratista, { ContratistaPo } from "@/entidades/Plantacion/Contratista";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";

import { Mutation, State } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
@Component
export default class FormularioPoApp extends Vue {
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;
  @State("sistemaId", { namespace: "authModule" }) sistemaId!: number;
  @Prop({ type: Number, required: true })
  action!: number;

  @Prop({ type: String, required: true })
  titulo!: string;
  @Prop({ required: true })
  modelo!: ContratistaPo;

  @Validations()
  validations = {
    material: { required },
    po: { required },
    contratistaId: { required },
  };
  material: string = "";
  po: string = "";
  contratistaPoId: number = 0;
  contratistaId: number = 0;
  listadoContratista: Contratista[] = [];

  //Metodos
  async ObtenerContratista() {
    try {
      this.mostrarLoading(new Loading(true, "Registrando informacion"));
      let response = await Contratista.ObtenerSelect(3);
      if (response.isSuccess == true) {
        this.listadoContratista = response.isResult;
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 1000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  guardarInfo() {
    this.modelo.contratistaId = this.contratistaId;
    this.modelo.material = this.material;
    this.modelo.po = this.po;
    this.modelo.contratistaPoId = this.contratistaPoId;
    this.$emit("guardar");
  }

  //Propiedas computadas

  //Computed
  get texto() {
    if (this.action == 1) {
      return "GUARDAR";
    } else {
      this.material = this.modelo.material;
      this.po = this.modelo.po;
      this.contratistaPoId = this.modelo.contratistaPoId;
      this.contratistaId = this.modelo.contratistaId;
      return "EDITAR";
    }
  }
  mounted() {
    this.ObtenerContratista();
  }
}
