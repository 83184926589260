









































import Vue from 'vue'
import {Component} from 'vue-property-decorator';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import Contratista,{ContratistaPo} from '@/entidades/Plantacion/Contratista';
import Formulario from '@/components/Contratista/FormularioPo.vue';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {Mutation,State} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta';

@Component({
    components:{
        TablaGeneral,
        Formulario
    }
})
export default class ContratistaPoView extends  Vue
{
    @Mutation('mostrarLoading',{namespace:'sistemaModule'}) mostrarLoading!:Function;
    @Mutation('mostarAlert',{namespace:'sistemaModule'}) mostarAlert!:Function;
    @State('sistemaId',{namespace:'authModule'}) sistemaId!:number;
    //Propiedas y atributos
    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Contratista",sortable:true,value:'contratistaName'},
            {text:"Po",sortable:true,value:'po'},
            {text:"Material",sortable:true,value:'material'},
            {text:"Editar",sortable:true,value:'actions'},
    ]
   listadoContratista:ContratistaPo[]=[];
   search:string ="";
   titulo:string = "";
   action:number = 1;
   modalAgregarContratista:boolean =false;
   modalAgregarContratistaName:string = "";
   contratistaModelo: ContratistaPo  = new ContratistaPo();
   loading:Loading |undefined = undefined;
    abrirModalAgregar()
    {
        this.action = 1 ;
        this.titulo = "Registro de Contratista PO";
        this.contratistaModelo = new ContratistaPo();
        this.modalAgregarContratistaName = "Formulario";
        this.modalAgregarContratista = true;
    }
   modalEditar(empleado:ContratistaPo)
   {
        this.contratistaModelo  = empleado;
        this.action = 2;
        this.titulo = "Editar Contratista";
        this.modalAgregarContratistaName = "Formulario";
        this.modalAgregarContratista = true;
   }
   cerrarModal()
   {
        this.action = 1 ;
        this.titulo = "Registro de Contratista";
        this.contratistaModelo = new ContratistaPo();
        this.modalAgregarContratistaName = "";
        this.modalAgregarContratista = false;
   }

   async guardarDb()
   {

       try
       {
            //this.loading = new Loading(true,"Cargando informacion");
            this.mostrarLoading(new Loading(true,"Registrando informacion"));
            let response = new ResponseGenerico(false,"");
            if(this.contratistaModelo != undefined)
            {
                response =  await Contratista.GuardarContrastitaPo(this.contratistaModelo);
            }
                
            if(response.isSuccess == true)
            {
                await this.ObtenerContratista();
                this.cerrarModal();
            }
            else
            {
                this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000))
            } 
       }
       catch(error)
       {
           //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000))
       }
       finally
       {
           this.mostrarLoading(new Loading(false,""));
           //this.mostarAlert(new Alerta("",false,"danger",1000))
       }
   }

   async editarEstado(contratista:Contratista)
   {
        try
        {
            let response = await Contratista.EditarEstado(contratista);
            
            if(response.isSuccess == true) await this.ObtenerContratista();

            let color : string = response.isSuccess == true ? 'info' : 'red';
            
        }
        catch(error)
        {   
            
        }
   }

    //Metodos async
    async ObtenerContratista()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Registrando informacion"));
             let response = await Contratista.ObtenerPoAll();
             if(response.isSuccess == true)
             {
                 this.listadoContratista = response.isResult;

             }
             else
             {
                 this.mostarAlert(new Alerta(response.isMessage,true,"danger",1000))
             }
        }
        catch(error)
        {
            //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",1000))
        }
        finally
        {
            this.mostrarLoading(new Loading(false,""));
        }
    }

   mounted() 
   {
       this.ObtenerContratista();
   }
}
